import React from 'react';

import { SelectInput } from '~/components/input';
import states from '~/fixtures/states';

interface Props {
	onChange: (e) => {};
	value?: string;
	narrow?: boolean;
	forceNativeFallback?: boolean;
	isShipping?: boolean;
}

const StateSelect: React.FC<Props> = (props) => {
	const shortHandStates = states
		.filter(({ value }) => !props.isShipping || !['GU', 'VI'].includes(value))
		.map(({ value }) => ({
			label: value,
			value
		}));

	return (
		<div>
			<SelectInput
				className="state-select fs-exclude"
				placeholder={props.narrow ? 'State' : 'Select your state'}
				blurInputOnSelect={true}
				label="State"
				name="state"
				options={shortHandStates}
				isSearchable={true}
				showNativeFallback
				{...props}
			/>
		</div>
	);
};

export default StateSelect;
